import React, { useEffect, useState } from "react";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";
import DynamicList from "../../ui/DynamicList";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    { id: "introduction", label: "Introduction" },
    { id: "vintage-glam", label: "Vintage Glam" },
    { id: "enchanted-forest", label: "Enchanted Forest" },
    { id: "celestial-dreams", label: "Celestial Dreams" },
    { id: "around-world", label: "Around the World" },
    { id: "fairytale-fantasy", label: "Fairytale Fantasy" },
    { id: "rustic-elegance", label: "Rustic Elegance" },
    { id: "modern-minimalist", label: "Modern Minimalist" },
    { id: "festival-inspired", label: "Festival-Inspired" },
    { id: "pop-culture", label: "Pop Culture Extravaganza" },
    { id: "seasonal-splendor", label: "Seasonal Splendor" },
    { id: "faqs", label: "FAQs" },
    { id: "conclusion", label: "Conclusion" },
  ];

  useEffect(() => {
    // console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "1920s Gatsby-Inspired Elegance",
      body: "Transport your guests to the roaring twenties with a Gatsby-inspired soirée. Think flapper-style dresses, art deco centerpieces, and a jazz band to set the mood. Champagne towers and vintage cars can add extra pizzazz.",
    },
    {
      header: "Retro 1950s Charm",
      body: "Channel the fun and quirky vibes of the 1950s with rockabilly fashion, polka dots, and a jukebox. Retro diners and soda floats can make your reception feel like a nostalgic blast from the past.",
    },
  ];

  const list2 = [
    {
      header: "Mystical Outdoor Settings",
      body: "Imagine exchanging vows under a canopy of trees with twinkling fairy lights and rustic wooden seating. A forest or garden venue is perfect for this magical theme.",
    },
    {
      header: "Natural, Woodland-Inspired Details",
      body: "Incorporate moss, ferns, and floral arches into your décor. Use earthy tones and organic textures for a truly immersive experience.",
    },
  ];

  const list3 = [
    {
      header: "Starry Night Décor",
      body: "Create a dreamy atmosphere with a celestial theme. Use navy blues, silvers, and golds, paired with starry backdrops and glittering lights.",
    },
    {
      header: "Cosmic Details",
      body: "Custom star maps showing the night sky on your wedding day, constellation-themed table names, and moon-shaped props can add an otherworldly charm.",
    },
  ];

  const list4 = [
    {
      header: "Globetrotting Adventure Theme",
      body: "Take your guests on a journey with travel-inspired elements like suitcase centerpieces, passport-style invites, and globes.",
    },
    {
      header: "Destination-Specific Weddings",
      body: "Bring the romance of Paris, the colors of Morocco, or the vineyards of Tuscany to your wedding with destination-inspired décor and cuisine.",
    },
  ];

  const list5 = [
    {
      header: "Royal Ball Vibes",
      body: "Turn your venue into a regal palace with chandeliers, velvet drapes, and opulent table settings. Guests will feel like they’ve stepped into a storybook.",
    },
    {
      header: "Whimsical Magic",
      body: "For a lighter fairytale feel, think pastel colors, unicorn-inspired details, and a sprinkle of glitter.",
    },
  ];

  const list6 = [
    {
      header: "Barn and Farm Settings",
      body: "Combine the charm of rural settings with elegant touches like string lights, burlap runners, and wooden accents.",
    },
    {
      header: "Country Chic Details",
      body: "Use mason jars for centerpieces, lace for tablecloths, and wildflowers for bouquets to achieve that perfect rustic vibe.",
    },
  ];

  const list7 = [
    {
      header: "Clean, Simple Lines",
      body: "Opt for sleek and modern with geometric shapes, monochrome palettes, and understated florals.",
    },
    {
      header: "Less is More Aesthetic",
      body: "Focus on quality over quantity with minimalist décor that emphasizes simplicity and elegance.",
    },
  ];

  const list8 = [
    {
      header: "Boho Chic Elements",
      body: "Infuse your day with boho vibes using teepees, macramé, and dreamcatchers. This theme works perfectly for outdoor venues.",
    },
    {
      header: "Outdoor Music Festival Vibes",
      body: "Create a laid-back atmosphere with live music, food trucks, and picnic-style seating.",
    },
  ];

  const list9 = [
    {
      header: "Fandom-Inspired Themes",
      body: "Celebrate your favorite fandom, whether it’s Harry Potter, Star Wars, or superheroes. Incorporate subtle nods or go all out with costumes and props.",
    },
    {
      header: "Movie or TV Show Themes",
      body: "For film buffs, a Hollywood glamour night or a nostalgic nod to your favorite sitcom can add a personal touch.",
    },
  ];

  const list10 = [
    {
      header: "Winter Wonderland",
      body: "Transform your venue into a snowy paradise with whites, silvers, and cozy elements like hot cocoa stations.",
    },
    {
      header: "Autumn Harvest",
      body: "Celebrate the season with warm tones, pumpkin centerpieces, and rustic foliage.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        10 Unique Wedding Theme Ideas That Will Wow Your Guests
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/PzCZqn47ejs?si=ZOVXfP_zm_Efh1lv"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction
        </h1>
        <p>
          Your wedding day is one of the most special days of your life, so why
          not make it unforgettable with a unique theme? A creative wedding
          theme not only sets the tone for your celebration but also leaves a
          lasting impression on your guests. Ready to turn your big day into a
          one-of-a-kind experience? Let’s dive into some inspiring ideas!
        </p>
      </section>

      <section id="vintage-glam" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Vintage Glam
        </h1>
        <DynamicList newLine listType="" data={list1} />
      </section>

      <section id="enchanted-forest" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Enchanted Forest
        </h1>
        <DynamicList newLine listType="" data={list2} />
      </section>

      <section id="celestial-dreams" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Celestial Dreams
        </h1>
        <DynamicList newLine listType="" data={list3} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/fairy-tale-lighting.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="around-world" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Around the World
        </h1>
        <DynamicList newLine listType="" data={list4} />
      </section>

      <section id="fairytale-fantasy" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Fairytale Fantasy
        </h1>
        <DynamicList newLine listType="" data={list5} />
      </section>

      <section id="rustic-elegance" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Rustic Elegance
        </h1>
        <DynamicList newLine listType="" data={list6} />
      </section>

      <section id="modern-minimalist" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Modern Minimalist
        </h1>
        <DynamicList newLine listType="" data={list7} />
      </section>

      <section id="festival-inspired" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Festival-Inspired
        </h1>
        <DynamicList newLine listType="" data={list8} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/boho-theme-decor.webp"
        alt="budget-decor-ideas"
      />

      <section id="pop-culture" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Pop Culture Extravaganza
        </h1>
        <DynamicList newLine listType="" data={list9} />
      </section>

      <section id="seasonal-splendor" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Seasonal Splendor
        </h1>
        <DynamicList newLine listType="" data={list10} />
      </section>

      <section id="faqs" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Frequently Asked Questions (FAQs)
        </h1>
        <ul className={`list-decimal ml-5 px-5 py-2 space-y-1`}>
          <li>
            <p className="font-semibold">
              How do I choose the best theme for my wedding?
            </p>
            <p>
              Reflect on your personality, shared interests, and dream
              aesthetics to find a theme that resonates with you.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              Can I combine two themes for a unique look?
            </p>
            <p>
              Yes! Blending themes like rustic elegance and fairytale magic can
              create a one-of-a-kind celebration.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              What’s the best way to incorporate a theme into wedding
              invitations?
            </p>
            <p>
              Use colors, fonts, and motifs that match your theme to set the
              tone from the start.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              Are themed{" "}
              <a
                href="/zzeeh_weddings"
                className="text-blue-500 font-semibold hover:underline"
              >
                weddings
              </a>{" "}
              more expensive than traditional ones?
            </p>
            <p>
              Not necessarily. Many themes can be achieved on a budget with DIY
              elements or creative sourcing.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              How do I find vendors to match my wedding theme?
            </p>
            <p>
              Look for vendors experienced in your chosen style and share your
              vision clearly to ensure alignment.
            </p>
          </li>
        </ul>
      </section>

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          A wedding theme is more than just aesthetics—it’s a reflection of your
          story and style. Whether you’re drawn to vintage charm, natural
          elegance, or modern simplicity, choose a theme that speaks to you. And
          don’t forget to add those personal touches that will leave your guests
          in awe!
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
