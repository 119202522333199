const VisionMission = () => {
  return (
    <section
      id="vision_mission"
      className="bg-white pt-12 pb-16 px-4 md:px-12 lg:px-16 xl:mx-auto max-w-[1920px] w-full"
    >
      <div className="flex flex-col items-center">
        <p className="text-sm font-semibold uppercase text-gold">
          Vision and Mission
        </p>
        <p className="text-2xl lg:text-4xl font-bold my-6 text-center">
          Crafting Unforgettable Wedding Experiences Like Never Before
        </p>
        <p className="text-[#4f4f4f] text-center mb-10">
          Planning an event can be overwhelming, so we let you enjoy the moments
          while we take care of the details.
        </p>
        <div className="flex flex-col lg:flex-row justify-center gap-8 lg:px-6">
          <div
            className="flex flex-col p-6 pb-0 gap-4 rounded-lg border relative bg-[#f2f2fc]"
            data-aos="flip-right"
            data-aos-delay="0"
          >
            <p className="font-semibold text-left text-2xl text-[#5e60e0]">
              Vision
            </p>
            <p className="text-gray-600 text-lg">
              To be the leading event company recognized globally for our
              excellence in event planning and production, known for our
              commitment to creativity, client satisfaction, and a relentless
              pursuit of perfection in every event we undertake
            </p>
            <img
              src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/images/vision.webp"
              alt="Vision"
              className="self-center w-[390px]"
            />
          </div>

          <div
            className="flex flex-col p-6 pb-0 gap-4 rounded-lg border relative bg-[#f5fffb]"
            data-aos="flip-left"
            data-aos-delay="200"
          >
            <p className="font-semibold text-left text-2xl text-[#27bf87]">
              Mission
            </p>
            <p className="text-gray-600 text-lg">
              To create unforgettable experiences by delivering innovative,
              high-quality event solutions that exceed client expectations,
              foster meaningful connections, and bring visions to life with
              creativity, precision and passion
            </p>
            <img
              src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/images/mission.webp"
              alt="Mission"
              className="self-center w-[290px]"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default VisionMission;
