import React, { useEffect, useState } from "react";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";
import DynamicList from "../../ui/DynamicList";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    { id: "introduction", label: "Introduction" },
    { id: "art-planning", label: "The Art of Wedding Planning" },
    { id: "perfect-atmosphere", label: "Designing the Perfect Atmosphere" },
    { id: "curating-experience", label: "Curating the Experience" },
    { id: "culinary-excellence", label: "Culinary Excellence" },
    { id: "managing-day", label: "Managing the Big Day" },
    { id: "vendor-collaboration", label: "Collaboration with Vendors" },
    { id: "post-services", label: "Post-Wedding Services" },
    { id: "conclusion", label: "Conclusion" },
  ];

  useEffect(() => {
    // console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Understanding the Couple’s Vision",
      body: "Every wedding starts with a story. At Zzeeh Weddings, our first step is understanding the couple—how they met, what they love, and their unique journey together. These insights shape our planning process. Whether it’s a traditional ceremony or a modern theme, our approach ensures every detail reflects the couple’s story.",
    },
    {
      header: "Translating Ideas into Reality",
      body: "Once we’ve captured the couple’s vision, we create a detailed plan that blends creativity and practicality. From securing wedding venues near me to finding the perfect mandap decorators near me, every element is aligned with their expectations.",
    },
  ];

  const list2 = [
    {
      header: "Venue Selection and Decoration",
      body: "The venue is the canvas for every wedding. Whether it’s stunning destination wedding packages in Goa or elegant wedding venues in Bangalore, we help couples choose spaces that align with their style. Our team then works magic with décor, lighting, and other elements to create a breathtaking ambiance.",
    },
    {
      header: "The Role of Lighting and Ambiance",
      body: "Lighting transforms a venue into a dreamlike setting. From romantic fairy lights for outdoor ceremonies to vibrant stage setups for receptions, our team uses lighting to enhance the mood and atmosphere.",
    },
    {
      header: "Wedding Decorations",
      body: "Our decor experts bring themes to life, creating stunning floral arrangements, enchanting mandaps, and elegant table settings that add to the beauty of the day.",
    },
  ];

  const list3 = [
    {
      header: "Thematic Weddings and Storytelling",
      body: "Imagine a wedding where every detail reflects your story. From custom invitations to decor that nods to shared memories, we ensure your wedding feels personal. Whether it’s a traditional Hindu wedding or a chic beachside ceremony, every element is infused with meaning.",
    },
    {
      header: "Entertainment and Engagement",
      body: "Entertainment is the heart of any celebration. We arrange live bands, DJs, dance performances, and even quirky photo booths to keep guests engaged. Our team ensures every moment feels special.",
    },
  ];

  const list4 = [
    {
      header: "Menu Tasting and Customization",
      body: "Food is a centerpiece of every wedding, and we collaborate with top chefs to design menus that cater to the couple’s preferences. Whether it’s fusion cuisine or traditional delicacies, the food becomes an experience in itself.",
    },
    {
      header: "Food Presentation and Serving Styles",
      body: "From elegant plated meals to lavish buffets, we focus on taste and presentation, ensuring the dining experience is as memorable as the event.",
    },
  ];

  const list5 = [
    {
      header: "Timeline Coordination",
      body: "The wedding day is packed with events, and our team ensures everything happens seamlessly. From the bride’s grand entrance to the final farewell, every detail is coordinated meticulously.",
    },
    {
      header: "Crisis Management",
      body: "Unexpected hiccups? Our team is trained to handle last-minute challenges with poise and creativity, ensuring the couple’s day is stress-free.",
    },
  ];

  const list6 = [
    {
      header: "Building Relationships with Trusted Partners",
      body: "Over the years, we’ve cultivated relationships with reliable vendors, including wedding photographers near me, videographers, caterers, and decorators. These partnerships allow us to consistently deliver top-quality services.",
    },
    {
      header: "Technology and Innovation",
      body: "We embrace technology to simplify wedding planning. From best wedding websites for inspiration to guest list management apps, we ensure the process is efficient and stress-free.",
    },
  ];

  const list7 = [
    {
      header: "Memories That Last a Lifetime",
      body: "Our collaboration with skilled wedding photographers and videographers near me ensures every magical moment is captured beautifully. From candid shots to cinematic highlights, the memories last forever.",
    },
    {
      header: "Feedback and Follow-Up",
      body: "Post-wedding feedback helps us refine our services and ensure we’ve exceeded expectations.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Behind the Scenes of a Zzeeh Wedding: How We Make Magic Happen
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/PzCZqn47ejs?si=ZOVXfP_zm_Efh1lv"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction
        </h2>
        <p>
          Have you ever wondered how dream weddings come to life? From fairy
          lights to heartfelt vows, every detail is a piece of a bigger puzzle.
          At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we don’t just plan events; we create memories. Whether it’s a grand
          Muslim wedding, an elaborate Hindu wedding, or an intimate destination
          wedding, our team specializes in turning every couple’s vision into a
          spectacular celebration. What’s the secret sauce? Let’s take you
          behind the scenes.
        </p>
      </section>

      <section id="art-planning" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          The Art of Wedding Planning
        </h2>
        <DynamicList newLine listType="" data={list1} />
      </section>

      <section id="perfect-atmosphere" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Designing the Perfect Atmosphere
        </h2>
        <DynamicList newLine listType="" data={list2} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/destination-wedding-planners.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="curating-experience" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Curating the Experience
        </h2>
        <DynamicList newLine listType="" data={list3} />
      </section>

      <section id="culinary-excellence" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Culinary Excellence
        </h2>
        <DynamicList newLine listType="" data={list4} />
      </section>

      <section id="managing-day" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Managing the Big Day
        </h2>
        <DynamicList newLine listType="" data={list5} />
      </section>

      <section id="vendor-collaboration" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Collaboration with Vendors
        </h2>
        <DynamicList newLine listType="" data={list6} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/best-wedding-venues-in-bangalore.webp"
        alt="budget-decor-ideas"
      />

      <section id="post-services" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Post-Wedding Services
        </h2>
        <DynamicList newLine listType="" data={list7} />
      </section>

      <section id="conclusion" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h2>
        <p>
          At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we specialize in making your dream wedding a reality. From seamless
          planning to flawless execution, we create events that reflect your
          love story. Whether you’re searching for the best wedding venues,
          expert planners, or complete destination wedding packages, we’re here
          to bring your vision to life. Let’s create memories that last a
          lifetime!
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
