import React from "react";
import { Accordion } from "flowbite-react";
import dawn from "../../assets/images/ui/gold-dawn.webp";

const FAQs = ({
  faqs,
}: {
  faqs: {
    ques: string;
    ans: string;
  }[];
}) => {
  return (
    <section
      id="faqs"
      className="relative py-10 lg:p-10 px-4 md:px-12 lg:px-16 xl:mx-auto max-w-[1920px] w-full"
    >
      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 rotate-180">
        <img src={dawn} alt="dawn" className="hidden lg:flex" />
      </div>

      <div className="flex flex-col justify-center space-x-0 space-y-10 lg:flex-row lg:space-y-0 lg:space-x-20">
        <p className="text-dark text-2xl lg:text-[40px] font-medium font-made-mirage lg:w-[360px] text-center lg:text-right leading-tight">
          Frequently Asked{" "}
          <i className="text-gold font-gt-super ml-1 lg:ml-0">Questions</i>
        </p>
        <div className="flex flex-col lg:w-[680px]">
          <Accordion className="border-0">
            {faqs.map((faq, index) => (
              <Accordion.Panel key={index}>
                <Accordion.Title
                  className={`bg-grey hover:bg-grey focus:ring-0 pb-5 ${
                    index === 0 ? "pt-0" : "pt-5"
                  }`}
                >
                  <p className="lg:text-lg text-dark font-medium">{faq.ques}</p>
                </Accordion.Title>
                <Accordion.Content className="!border-0 pt-0">
                  <p className="lg:text-lg text-purple">{faq.ans}</p>
                </Accordion.Content>
              </Accordion.Panel>
            ))}
          </Accordion>
          <div className="ml-5">
            <p className="text-purple font-medium mt-8 mb-2">
              Have more questions?
            </p>
            <p className="text-lg lg:text-xl font-medium">
              Reach out to us at{" "}
              <a className="text-gold hover:opacity-80" href="tel:919886400292">
                +91 9110466643
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
