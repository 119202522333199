import React from "react";
import NavigationBar from "../components/ui/Navbar";
import Footer from "../components/ui/Footer";
import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";
import PlanOne from "../components/Weddings/PlanOne";
import { Location, Mail, Pendant, Phone } from "../assets/icons/Icons";

const ContactUs = () => {
  const markerLocation = {
    lat: 12.9992431,
    lng: 77.6131703,
  };

  return (
    <div className="flex flex-col items-center bg-[#f6f6f6]">
      <NavigationBar />
      <div
        id="contact-us"
        className="min-h-screen py-20 md:py-32 px-4 container"
      >
        <PlanOne />

        <div className="flex flex-col rounded-xl p-10 bg-[#A18DA31A] border border-[#5A495B80] md:mx-5 my-10 lg:my-20">
          <div className="self-center flex flex-row items-center space-x-5">
            <div className="rotate-180">
              <Pendant />
            </div>
            <h1 className="self-center whitespace-nowrap text-lg lg:text-xl text-pureGold font-semibold font-made-mirage uppercase">
              Contact Us
            </h1>
            <Pendant />
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mt-10">
            <div className="flex flex-col space-y-5">
              <p className="text-xl font-medium">Corporate Office</p>
              <div className="flex flex-row items-center">
                <p className="mr-2">
                  <Location />
                </p>
                <a
                  href="https://maps.app.goo.gl/SBWc5geAdSztwTyRA"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-gold lg:max-w-[400px]"
                >
                  #19, Rahmans Grace, above Sangeeta showroom, Pulikeshi Nagar,
                  Bengaluru, Karnataka 560005
                </a>
              </div>
              <div className="flex flex-row items-center">
                <p className="mr-2">
                  <Phone fill="#766478" />
                </p>
                <a href="tel:+919110466643" className="hover:text-gold">
                  +91 91104 66643
                </a>
              </div>
              <div className="flex flex-row items-center">
                <p className="mr-2">
                  <Mail fill="#766478" />
                </p>
                <a
                  href="mailto:info@zzeeh.com"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-gold"
                >
                  info@zzeeh.com
                </a>
              </div>
            </div>

            <div className="flex flex-col space-y-5">
              <p className="text-xl font-medium">Dubai Office</p>
              <div className="flex flex-row items-center">
                <p className="mr-2">
                  <Location />
                </p>
                <a
                  href="https://maps.app.goo.gl/4jLnRWJaDMFfiAL17"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-gold lg:max-w-[400px]"
                >
                  Office Number 315, Metha Plaza Building, Oud Metha, Dubai,
                  United Arab Emirates
                </a>
              </div>
              <div className="flex flex-row items-center">
                <p className="mr-2">
                  <Phone fill="#766478" />
                </p>
                <a href="tel:+971562714244" className="hover:text-gold">
                  +971 56 271 4244
                </a>
              </div>
              <div className="flex flex-row items-center">
                <p className="mr-2">
                  <Mail fill="#766478" />
                </p>
                <a
                  href="mailto:info@zzeeh.com"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-gold"
                >
                  info@zzeeh.com
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="h-80 xl:h-96 w-full md:px-10 mt-10 lg:mt-20">
          <APIProvider apiKey="AIzaSyCwePEQjXZuC5OCIyjEHvnzjBZZAUGu2Hc">
            <Map
              mapId="1b3b9ffa5d4fd0ab"
              defaultZoom={13}
              defaultCenter={markerLocation}
              gestureHandling={"greedy"}
            >
              <AdvancedMarker position={markerLocation} />
            </Map>
          </APIProvider>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ContactUs;
