import React, { useEffect, useState } from "react";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";
import DynamicList from "../../ui/DynamicList";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "why-budget",
      label: "Why Budget Planning Is Crucial for Weddings in 2025",
    },
    { id: "avg-cost", label: "Average Cost of Weddings in Bangalore" },
    { id: "venue-costs", label: "Venue Costs in 2025" },
    { id: "catering-food", label: "Catering and Food Expenses" },
    { id: "decor-theme", label: "Décor and Theme Expenses" },
    { id: "photo-video", label: "Photography and Videography" },
    { id: "clothing-accessories", label: "Clothing and Accessories" },
    { id: "entertainment", label: "Entertainment Costs" },
    { id: "invitations", label: "Invitations and Stationery" },
    { id: "guest-travel", label: "Guest Accommodation and Travel" },
    { id: "additional-costs", label: "Additional Costs to Consider" },
    { id: "budget-tips", label: "Budget Optimization Tips" },
    { id: "wedding-planners", label: "The Role of Wedding Planners" },
    { id: "last-minute", label: "Dealing with Last-Minute Expenses" },
    { id: "sustainability", label: "Sustainability and Budget Planning" },
  ];

  useEffect(() => {
    // console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Rising Costs of Weddings",
      body: "Weddings in Bangalore are becoming increasingly elaborate, leading to significant price hikes in venues, catering, and other services. Inflation and heightened demand in the wedding industry are expected to drive costs further up in 2025.",
    },
    {
      header: "Importance of a Well-Defined Budget",
      body: "A well-planned budget helps manage expenses, prioritise essentials, and prevent overspending, providing clarity and reducing stress during wedding preparations.",
    },
  ];

  const list2 = [
    {
      header: "Venue",
      body: "₹2–10 lakhs per day, depending on the location and facilities.",
    },
    {
      header: "Catering",
      body: "₹800–₹2,500 per plate, influenced by menu selection and service style.",
    },
    {
      header: "Décor",
      body: "₹1–5 lakhs, based on theme complexity and materials used.",
    },
    {
      header: "Photography",
      body: "₹1–3 lakhs, varying with the photographer's expertise and package inclusions.",
    },
    {
      header: "Clothing",
      body: "₹1–4 lakhs for both bride and groom, depending on designer choices and customisations.",
    },
  ];

  const list3 = [
    {
      header: "Book in Advance",
      body: "Securing a venue early can lock in current rates and provide more options.",
    },
    {
      header: "Opt for Weekday or Off-Season Dates",
      body: "These periods often come with discounts due to lower demand.",
    },
    {
      header: "Combine Ceremony and Reception Venues",
      body: "This strategy can save on logistics and transportation costs.",
    },
  ];

  const list4 = [
    {
      header: "Choose Seasonal Ingredients",
      body: "Utilising in-season produce can reduce costs and ensure freshness.",
    },
    {
      header: "Opt for Buffet-Style Catering",
      body: "Buffets can be more economical than plated meals and offer variety.",
    },
    {
      header: "Limit Menu Options",
      body: "A curated menu can minimise food waste and control expenses.",
    },
  ];

  const list5 = [
    {
      header: "Incorporate Reusable Décor Items",
      body: "Using items that can be repurposed reduces costs and environmental impact.",
    },
    {
      header: "Focus on a Statement Piece",
      body: "Investing in a single standout element, like a floral arch, can create a lasting impression without overspending.",
    },
  ];

  const list6 = [
    {
      header: "Consider Emerging Photographers",
      body: "New talent may offer competitive rates for quality work.",
    },
    {
      header: "Explore Student Portfolios",
      body: "Photography students often provide services at lower costs to build their portfolios.",
    },
  ];

  const list7 = [
    {
      header: "Managing Hotel Block Costs",
      body: "Hotels in Bangalore charge ₹3,000–₹10,000 per night for group bookings. Negotiating group discounts can lead to significant savings.",
    },
    {
      header: "Transport Arrangements for Guests",
      body: "Budget ₹30,000–₹1 lakh for guest transport, depending on the distance and number of vehicles required.",
    },
  ];

  const list8 = [
    {
      header: "Makeup and Styling",
      body: "Bridal makeup packages in 2025 are expected to range between ₹20,000 and ₹1 lakh. Grooming services for men start at ₹10,000.",
    },
    {
      header: "Miscellaneous Expenses",
      body: "Include costs for favours, event insurance, and tips for staff in your budget to avoid surprises.",
    },
  ];

  const list9 = [
    {
      header: "DIY Ideas for Cost Savings",
      body: "DIY décor, invitations, and favors can significantly reduce expenses while adding a personal touch.",
    },
    {
      header: "Prioritizing Essential Over Optional Elements",
      body: "Focus on aspects that matter most to you, like food or photography, and minimize spending on less impactful elements.",
    },
  ];

  const list10 = [
    {
      header: "Benefits of Hiring a Planner",
      body: "Planners can negotiate better deals, streamline logistics, and ensure smooth execution, often saving more than their fees.",
    },
    {
      header: "Choosing the Right Planner",
      body: "Research reviews, request portfolios, and discuss budgets before hiring.",
    },
  ];

  const list11 = [
    {
      header: "Creating a Contingency Fund",
      body: "Set aside 10–15% of your total budget for unexpected costs like weather-related changes or guest count adjustments.",
    },
    {
      header: "Avoiding Common Budget Pitfalls",
      body: "Track all expenses meticulously and avoid impulsive decisions during the planning process.",
    },
  ];

  const list12 = [
    {
      header: "Eco-Friendly Weddings on a Budget",
      body: "Use biodegradable materials, local flowers, and digital invites to reduce environmental impact and costs.",
    },
    {
      header: "Reducing Waste to Save Money",
      body: "Plan portions carefully to avoid food wastage and opt for reusable décor.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Wedding Budget Planning in Bangalore: What to Expect in 2025
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/PzCZqn47ejs?si=ZOVXfP_zm_Efh1lv"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <p>
        Planning a wedding in Bangalore for 2025 requires meticulous budgeting
        to ensure a memorable yet financially manageable event. This guide
        provides insights into expected costs and offers tips to optimise your
        wedding budget effectively.
      </p>

      <TableofContent tocData={tocData} />

      <section id="why-budget" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Budget Planning Is Crucial for Weddings in 2025
        </h2>
        <DynamicList newLine listType="" data={list1} />
      </section>

      <section id="avg-cost" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Average Cost of Weddings in Bangalore
        </h2>
        <p className="font-semibold">Factors Influencing Wedding Expenses</p>
        <p>
          Key factors include the choice of venue, number of guests, type of
          catering, and level of customisation in décor. Add-ons like
          entertainment, luxury accommodations, and photography also contribute
          to the overall cost.
        </p>
        <p className="font-semibold">Breakdown of Typical Costs</p>
        <DynamicList listType="list-disc" data={list2} />
      </section>

      <section id="venue-costs" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Venue Costs in 2025
        </h2>
        <p className="font-semibold">Popular Venues and Their Price Ranges</p>
        <p>
          Top venues like The Tamarind Tree, ITC Windsor, and Leela Palace range
          between ₹4–15 lakhs for a day. Smaller boutique venues may cost ₹2–5
          lakhs.
        </p>

        <p className="font-semibold">Tips for Booking Budget-Friendly Venues</p>
        <DynamicList listType="list-disc" data={list3} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-reception-stage.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="catering-food" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Catering and Food Expenses
        </h2>
        <p className="font-semibold">Expected Costs for Bangalore Cuisine</p>
        <p>
          Bangalore weddings often feature elaborate multi-cuisine menus,
          costing ₹800–₹2,500 per plate, depending on the variety and quality of
          ingredients.
        </p>
        <p className="font-semibold">Options for Cost Optimisation</p>
        <DynamicList listType="list-disc" data={list4} />
      </section>

      <section id="decor-theme" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Décor and Theme Expenses
        </h2>
        <p className="font-semibold">Trending Themes for 2025</p>
        <p>
          Rustic elegance, eco-friendly weddings, and modern minimalism are
          anticipated to be popular. Themes can cost ₹2–6 lakhs depending on
          scale and intricacy.
        </p>
        <p className="font-semibold">Balancing Creativity and Costs</p>
        <DynamicList listType="list-disc" data={list5} />
      </section>

      <section id="photo-video" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Photography and Videography
        </h2>
        <p className="font-semibold">Hiring Professional Services</p>
        <p>
          Professional photographers in Bangalore charge ₹1–3 lakhs for a
          wedding, depending on the duration and deliverables like albums or
          highlight reels.
        </p>
        <p className="font-semibold">Budget-Friendly Alternatives</p>
        <DynamicList listType="list-disc" data={list6} />
      </section>

      <section id="clothing-accessories" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Clothing and Accessories
        </h2>
        <p className="font-semibold">Bridal Wear Costs in 2025</p>
        <p>
          Designer bridal outfits can cost ₹1.5–3 lakhs, while boutique options
          range from ₹50,000 to ₹1.2 lakhs. Renting is also a viable option for
          budget-conscious brides.
        </p>
        <p className="font-semibold">Groom and Family Attire Budgets</p>
        <p>
          Allocate ₹40,000–₹1 lakh for the groom’s attire and similar amounts
          for immediate family members.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/bridal-entry-moments.webp"
        alt="budget-decor-ideas"
      />

      <section id="entertainment" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Entertainment Costs
        </h2>
        <p className="font-semibold">Popular Entertainment Options</p>
        <p>
          Live bands, DJs, and cultural performances are common, costing
          ₹50,000–₹2 lakhs depending on the artist's reputation.
        </p>
        <p className="font-semibold">Managing Entertainment Within Budget</p>
        <p>
          Limit Entertainment to Key Events: Focusing on one or two main events,
          such as a sangeet night, can help stay within budget.
        </p>
      </section>

      <section id="invitations" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Invitations and Stationery
        </h2>
        <p className="font-semibold">Digital vs. Printed Invites</p>
        <p>
          Digital invitations are cost-effective and eco-friendly, while printed
          cards with intricate designs can cost ₹50–₹500 per card.
        </p>
        <p className="font-semibold">Personalisation Trends</p>
        <p>
          Customised monograms and interactive elements like QR codes are
          gaining popularity but may add to the cost.
        </p>
      </section>

      <section id="guest-travel" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Guest Accommodation and Travel
        </h2>
        <DynamicList newLine listType="" data={list7} />
      </section>

      <section id="additional-costs" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Additional Costs to Consider
        </h2>
        <DynamicList newLine listType="" data={list8} />
      </section>

      <section id="budget-tips" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Budget Optimisation Tips
        </h2>
        <DynamicList newLine listType="" data={list9} />
      </section>

      <section id="wedding-planners" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          The Role of Wedding Planners
        </h2>
        <DynamicList newLine listType="" data={list10} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-decoration.webp"
        alt="success-stories"
      />

      <section id="last-minute" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Dealing with Last-Minute Expenses
        </h2>
        <DynamicList newLine listType="" data={list11} />
      </section>

      <section id="sustainability" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Sustainability and Budget Planning
        </h2>
        <DynamicList newLine listType="" data={list12} />
      </section>

      <section id="faqs" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Frequently Asked Questions (FAQs)
        </h2>
        <ul className={`list-decimal ml-5 px-5 py-2 space-y-1`}>
          <li>
            <p className="font-semibold">
              What is the average wedding budget in Bangalore for 2025?
            </p>
            <p>₹15–50 lakhs, depending on scale and preferences.</p>
          </li>
          <li>
            <p className="font-semibold">
              How can I save on wedding expenses in Bangalore?
            </p>
            <p>
              Book early, prioritize essentials, and consider DIY options for
              décor and invites.
            </p>
          </li>
          <li>
            <p className="font-semibold">Are weekday weddings cheaper?</p>
            <p>
              Yes, venues and vendors often offer discounts for weekday
              bookings.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              How much should I allocate for catering?
            </p>
            <p>Allocate ₹800–₹2,500 per guest, depending on the menu.</p>
          </li>
          <li>
            <p className="font-semibold">
              Is hiring a wedding planner worth it?
            </p>
            <p>
              Yes, they can save time, negotiate better deals, and reduce stress
              during planning.
            </p>
          </li>
        </ul>
      </section>

      <section id="conclusion" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h2>
        <p>
          Wedding budget planning in Bangalore for 2025 requires a strategic
          approach. By focusing on priorities, exploring cost-effective options,
          and maintaining a contingency fund, couples can create memorable
          celebrations without financial strain.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
