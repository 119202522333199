import React, { useEffect, useState } from "react";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";
import DynamicList from "../../ui/DynamicList";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "multi-day-popular",
      label: "Why Multi-Day Weddings Are Popular in Bangalore",
    },
    { id: "setting-foundation", label: "Setting the Foundation" },
    { id: "venue-selection", label: "Venue Selection for Multi-Day Events" },
    { id: "guest-management", label: "Guest Management Essentials" },
    { id: "event-schedules", label: "Event Schedules and Flow" },
    { id: "catering-menu", label: "Catering and Menu Planning" },
    { id: "decor-theme", label: "Décor and Theme Execution" },
    { id: "entertainment", label: "Entertainment Options" },
    { id: "vendor-coordination", label: "Vendor Coordination" },
    { id: "rituals-ceremonies", label: "Managing Rituals and Ceremonies" },
    { id: "tech-integration", label: "Tech Integration in Wedding Planning" },
    { id: "budget-tips", label: "Budget Optimization Tips" },
    { id: "sustainability", label: "Sustainability in Weddings" },
    { id: "day-challenges", label: "Dealing with Wedding Day Challenges" },
    { id: "post-wedding", label: "Post-Wedding Logistics" },
  ];

  useEffect(() => {
    // console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Cultural Significance of Multi-Day Weddings",
      body: "Bangalore is a melting pot of cultures, and multi-day weddings have deep roots in traditions. Celebrations like mehendi, sangeet, and the reception each hold unique significance and create an unforgettable experience for families.",
    },
    {
      header: "Bangalore as a Wedding Destination",
      body: "The city is known for its stunning venues, pleasant weather, and modern amenities. From luxurious resorts to traditional wedding halls, Bangalore offers something for every couple.",
    },
  ];

  const list2 = [
    {
      header: "Deciding on a Wedding Budget",
      body: "Your budget dictates every decision, from venues to catering. Break it down by categories like décor, food, and entertainment to ensure no surprises.",
    },
    {
      header: "Choosing the Perfect Dates",
      body: "Consider auspicious dates, weather conditions, and guest availability to avoid conflicts. Bangalore’s monsoon season can be tricky, so planning around it is wise.",
    },
  ];

  const list3 = [
    {
      header: "Top Wedding Venues in Bangalore",
      body: "Popular venues include The Leela Palace, ITC Gardenia, and Palace Grounds. These locations are equipped to handle multi-day events with ease.",
    },
    {
      header: "Booking Guidelines and Tips",
      body: "Start early—venues in Bangalore book out fast, especially during peak wedding season. Negotiate packages to include multiple event days.",
    },
  ];

  const list4 = [
    {
      header: "Invitation Trends for Multi-Day Weddings",
      body: "Opt for digital invites with detailed itineraries. Pair them with personalized printed cards for a traditional touch.",
    },
    {
      header: "Accommodation and Travel Arrangements",
      body: "Block hotel rooms near the venue and arrange transportation for out-of-town guests. Bangalore’s traffic can be unpredictable, so plan buffer times.",
    },
  ];

  const list5 = [
    {
      header: "Typical Itinerary for a Multi-Day Wedding",
      body: "Day 1: Welcome dinner and mehendi, Day 2: Sangeet and cocktail party, Day 3: Main wedding ceremony, Day 4: Reception",
    },
    {
      header: "Including Cultural and Modern Elements",
      body: "Blend rituals like haldi with fun elements like photo booths and choreographed dance performances.",
    },
  ];

  const list6 = [
    {
      header: "Popular Bangalore Cuisine for Weddings",
      body: "Delight guests with local favorites like bisibele bath, ragi mudde, and Mysore pak. Add an international twist with fusion dishes.",
    },
    {
      header: "Dietary Preferences and Custom Menus",
      body: "Offer vegetarian, vegan, and gluten-free options to accommodate diverse palates.",
    },
  ];

  const list7 = [
    {
      header: "Trending Themes for Bangalore Weddings",
      body: "Themes like floral fantasy, royal palace, and rustic chic are trending. Match the décor to your theme for consistency.",
    },
    {
      header: "Balancing Tradition and Creativity",
      body: "Combine traditional elements like temple bells with modern aesthetics like LED lighting for a unique look.",
    },
  ];

  const list8 = [
    {
      header: "Musical Performances and DJs",
      body: "From classical Carnatic music to upbeat Bollywood DJs, Bangalore has top-notch performers to keep the energy high.",
    },
    {
      header: "Unique Guest Activities",
      body: "Host workshops like pottery or arrange city tours for guests to explore Bangalore’s charm.",
    },
  ];

  const list9 = [
    {
      header: "Building a Reliable Vendor Team",
      body: "Choose vendors with multi-day wedding experience. Schedule regular check-ins to keep everything on track.",
    },
    {
      header: "Avoiding Last-Minute Hassles",
      body: "Finalize all contracts and ensure backup plans are in place for key aspects like catering and photography.",
    },
  ];

  const list10 = [
    {
      header: "Key Hindu Wedding Rituals",
      body: "Important rituals include the kanyadaan, mangalsutra ceremony, and saat pheras. Consult a pandit to ensure everything is seamless.",
    },
    {
      header: "Incorporating Fusion Weddings",
      body: "Blend rituals from different cultures to create a wedding that represents both families.",
    },
  ];

  const list11 = [
    {
      header: "Using Wedding Apps and Tools",
      body: "Apps like WedMeGood and WeddingWire simplify vendor bookings and schedule tracking.",
    },
    {
      header: "Leveraging Social Media for Updates",
      body: "Create a wedding hashtag and share live updates to keep guests engaged.",
    },
  ];

  const list12 = [
    {
      header: "Allocating Funds Smartly",
      body: "Prioritize essentials like venue and food over extras like party favors.",
    },
    {
      header: "DIY Ideas for Budget-Friendly Glam",
      body: "Incorporate DIY décor like handmade centerpieces to save costs while adding a personal touch.",
    },
  ];

  const list13 = [
    {
      header: "Eco-Friendly Décor Choices",
      body: "Use reusable materials, organic flowers, and LED lighting for a green wedding.",
    },
    {
      header: "Managing Waste During Events",
      body: "Collaborate with vendors to minimize food and décor waste.",
    },
  ];

  const list14 = [
    {
      header: "Common Issues and Solutions",
      body: "From sudden rain to vendor delays, prepare contingency plans to avoid stress.",
    },
    {
      header: "Staying Calm Under Pressure",
      body: "Delegate tasks to a wedding planner or trusted family member to keep the day running smoothly.",
    },
  ];

  const list15 = [
    {
      header: "Thank You Notes and Guest Feedback",
      body: "Show appreciation with personalized thank-you cards. Request feedback to improve future events.",
    },
    {
      header: "Settling Final Payments",
      body: "Clear all dues promptly and review contracts for any overlooked terms.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Multi-Day Wedding Planning in Bangalore: Logistics and Management
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/PzCZqn47ejs?si=ZOVXfP_zm_Efh1lv"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="multi-day-popular" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Multi-Day Weddings Are Popular in Bangalore
        </h2>
        <DynamicList newLine listType="" data={list1} />
      </section>

      <section id="setting-foundation" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Setting the Foundation
        </h2>
        <DynamicList newLine listType="" data={list2} />
      </section>

      <section id="venue-selection" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Venue Selection for Multi-Day Events
        </h2>
        <DynamicList newLine listType="" data={list3} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/haldi-ceremony.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="guest-management" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Guest Management Essentials
        </h2>
        <DynamicList newLine listType="" data={list4} />
      </section>

      <section id="event-schedules" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Event Schedules and Flow
        </h2>
        <DynamicList newLine listType="" data={list5} />
      </section>

      <section id="catering-menu" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Catering and Menu Planning
        </h2>
        <DynamicList newLine listType="" data={list6} />
      </section>

      <section id="decor-theme" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Décor and Theme Execution
        </h2>
        <DynamicList newLine listType="" data={list7} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-stage-decor.webp"
        alt="success-stories"
      />

      <section id="entertainment" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Entertainment Options
        </h2>
        <DynamicList newLine listType="" data={list8} />
      </section>

      <section id="vendor-coordination" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Vendor Coordination
        </h2>
        <DynamicList newLine listType="" data={list9} />
      </section>

      <section id="rituals-ceremonies" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Managing Rituals and Ceremonies
        </h2>
        <DynamicList newLine listType="" data={list10} />
      </section>

      <section id="tech-integration" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Tech Integration in Wedding Planning
        </h2>
        <DynamicList newLine listType="" data={list11} />
      </section>

      <section id="budget-tips" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Budget Optimization Tips
        </h2>
        <DynamicList newLine listType="" data={list12} />
      </section>

      <section id="sustainability" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Sustainability in Weddings
        </h2>
        <DynamicList newLine listType="" data={list13} />
      </section>

      <section id="day-challenges" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Dealing with Wedding Day Challenges
        </h2>
        <DynamicList newLine listType="" data={list14} />
      </section>

      <section id="post-wedding" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Post-Wedding Logistics
        </h2>
        <DynamicList newLine listType="" data={list15} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/mandap-decoration.webp"
        alt="budget-decor-ideas"
      />

      <section id="faqs" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Frequently Asked Questions (FAQs)
        </h2>
        <ul className={`list-decimal ml-5 px-5 py-2 space-y-1`}>
          <li>
            <p className="font-semibold">
              What is the ideal budget for a multi-day wedding in Bangalore?
            </p>
            <p>
              It varies based on preferences but typically ranges from ₹15–50
              lakhs or more.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              How early should I book venues for my wedding?
            </p>
            <p>
              It’s best to book 9–12 months in advance to secure your preferred
              dates.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              What’s the best season for weddings in Bangalore?
            </p>
            <p>October to February is ideal due to pleasant weather.</p>
          </li>
          <li>
            <p className="font-semibold">
              Can I incorporate destination wedding elements in Bangalore?
            </p>
            <p>
              Absolutely! Bangalore offers resorts and heritage venues perfect
              for destination-style weddings.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              Do I need a wedding planner for a multi-day wedding?
            </p>
            <p>
              While not mandatory, a planner can ease logistics and ensure
              smooth execution.
            </p>
          </li>
        </ul>
      </section>

      <section id="conclusion" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h2>
        <p>
          Planning a multi-day wedding in Bangalore is a rewarding journey that
          blends tradition with modernity. By paying attention to every
          detail—from venues to guest management—you can create a celebration
          that’s as unforgettable as your love story.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
