import React from "react";

const Hero = () => {
  return (
    <section
      id="about_us"
      className="py-12 px-4 md:px-12 lg:px-16 xl:mx-auto max-w-[1920px] w-full"
    >
      <div className="flex flex-col items-center mt-20">
        <div className="flex flex-col text-center xl:w-[60vw]">
          <p className="text-sm font-semibold uppercase text-gold">About Us</p>
          <p className="text-2xl lg:text-4xl font-bold mt-6">
            Zzeeh Weddings: Your Trusted Partner in Celebration
          </p>
          <p className="text-justify text-last-center text-purple mt-4">
            At Zzeeh Weddings, we believe that every occasion deserves to be
            celebrated with unparalleled grandeur and elegance. With a rich
            legacy of crafting extraordinary weddings, we have established
            ourselves as the preferred choice for discerning clients seeking a
            touch of brilliance. Our journey began with a simple passion for
            creating unforgettable experiences. Over the years, we have honed
            our skills and expanded our expertise to encompass a wide range of
            weddings, from intimate gatherings to lavish affairs. What sets us
            apart is our unwavering commitment to understanding our clients'
            vision and transforming it into a reality that surpasses
            expectations.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
